<template>
  <div id="hero-banner" class="image-collage" ref="imageCtn">
    <div 
      v-for="(img, i) in allImages"
      class="image-collage__square" 
      :style="styleBoxSize"
      :key="`${img}-${i}`"
    >
      <!-- <img :src="img.path" :alt="img.alt" :style="styleBoxSize" /> -->
      <VLazyImage :src="img.path" :style="styleBoxSize" />
      <!-- <div class="overlay" :style="styleBoxSize"></div> -->
    </div>

    <div class="image-collage__overlay" :style="overlayStyle">
      <div 
        class="image-collage__intro-banner" 
        ref="imageBnr"
      >
        <h1>The Esthetic Studio Co</h1>
        <div class="image-collage__intro-banner-slogan">
          <span>Your Journey to Total Beauty Begins Here!</span>
        </div>
        <div class="image-collage__intro-banner-specials">
          <span>New to Our Services?</span>
          <br/>
          <span>Take 15% Off Your First Visit!</span>
        </div>
        <div class="image-collage__intro-banner-btn-ctn">
          <a href="https://squareup.com/appointments/book/cnho91qog5230e/M8TAW1GSWPWRV/start">
            <button>Book Now</button>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, inject, ref } from 'vue';
import VLazyImage from "v-lazy-image";

const imageCtn = ref(null);
const imageBnr = ref(null);
const sizes = inject('sizes');
const getSizes = computed(() => sizes.value)

const allImages = [
  {
    name: 'High Frequency',
    path: 'https://res.cloudinary.com/dqcybarml/image/upload/v1692477764/Tezza-2236_qvcomb.jpg',
    alt: 'high_frequency_tool'
  },
  {
    name: 'High Frequency',
    path: 'https://res.cloudinary.com/dqcybarml/image/upload/v1692477765/Tezza-6454_wyciwd.jpg',
    alt: 'high_frequency_tool'
  },
  {
    name: 'High Frequency',
    path: 'https://res.cloudinary.com/dqcybarml/image/upload/v1692477773/Tezza-6999_ygfexf.jpg',
    alt: 'high_frequency_tool'
  },
  {
    name: 'High Frequency',
    path: 'https://res.cloudinary.com/dqcybarml/image/upload/v1692477769/Tezza-6263_jjzgr8.jpg',
    alt: 'high_frequency_tool'
  },
  {
    name: 'High Frequency',
    path: 'https://res.cloudinary.com/dqcybarml/image/upload/v1692477768/Tezza-2145_m175a7.jpg',
    alt: 'high_frequency_tool'
  },
  {
    name: 'High Frequency',
    path: 'https://res.cloudinary.com/dqcybarml/image/upload/v1692477769/Tezza-3737_btggdg.jpg',
    alt: 'high_frequency_tool'
  },
  {
    name: 'High Frequency',
    path: 'https://res.cloudinary.com/dqcybarml/image/upload/v1692477771/Tezza-5337_lyxztb.jpg',
    alt: 'high_frequency_tool'
  },
  {
    name: 'High Frequency',
    path: 'https://res.cloudinary.com/dqcybarml/image/upload/v1692477771/Tezza-6757_zedgzu.jpg',
    alt: 'high_frequency_tool'
  },
  {
    name: 'High Frequency',
    path: 'https://res.cloudinary.com/dqcybarml/image/upload/v1692477766/Tezza-8308_k2fww1.jpg',
    alt: 'high_frequency_tool'
  },
  {
    name: 'High Frequency',
    path: 'https://res.cloudinary.com/dqcybarml/image/upload/v1692479654/Tezza-3738_tjgujs.jpg',
    alt: 'high_frequency_tool'
  },
  {
    name: 'High Frequency',
    path: 'https://res.cloudinary.com/dqcybarml/image/upload/v1692477766/Tezza-0070_eh4wdw.jpg',
    alt: 'high_frequency_tool'
  },
  {
    name: 'High Frequency',
    path: 'https://res.cloudinary.com/dqcybarml/image/upload/v1692477763/Tezza-0611_ndy89z.jpg',
    alt: 'high_frequency_tool'
  },
  {
    name: 'High Frequency',
    path: 'https://res.cloudinary.com/dqcybarml/image/upload/v1692477768/Tezza-7795_xkl9av.jpg',
    alt: 'high_frequency_tool'
  },
  {
    name: 'High Frequency',
    path: 'https://res.cloudinary.com/dqcybarml/image/upload/v1692481084/Tezza-5555_icsjlo.jpg',
    alt: 'high_frequency_tool'
  },
  {
    name: 'High Frequency',
    path: 'https://res.cloudinary.com/dqcybarml/image/upload/v1692481153/Tezza-4271_tzqayl.jpg',
    alt: 'high_frequency_tool'
  },
];

const styleBoxSize = computed(() => {
  const width = `${getSizes.value.width / gridDivision.value}px`;
  const height = width;
  
  return {
    width,
    height,
  };
});

const gridDivision = computed(() => {
  const screenWidth = getSizes.value.width;

  let gridSize = 3;

  if (screenWidth > 700) gridSize = 5;

  return gridSize;
});

const overlayStyle = computed(() => {
console.log(gridDivision.value * styleBoxSize.value.width);

  return {
    width: `${getSizes.value.width}px`,
    height: `${(gridDivision.value === 3 ? 5 : 3) * (getSizes.value.width / gridDivision.value)}px`,
  };
});
</script>

<style lang="scss" scoped>
@import '../assets/style/base/variables.scss';

.image-collage {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  &__overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 66;
    left: 0;
  }

  &__square {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      background-size: contain;
    }
  }

  &__intro-banner {
    position: absolute;
    width: 80vw;
    height: fit-content;
    background-color: rgba(0,0,0,0.5);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 0;

    h1 {
      font-size: 24px;
      letter-spacing: 3px;
      font-weight: 500;
    }

    &-slogan {
      font-size: 12px;
      text-align: center;
    }

    &-specials {
      margin-top: 30px;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
    }

    &-btn-ctn {
      margin-top: 50px;
      
      button {
        height: 50px;
        width: 150px;
        color: $color-green-highlight;
        font-size: 16px;
        font-weight: 600;
        background-color: $theme-primary-body-bg-color;
        border: none;
        border-radius: 30px;
      }
    }
  }
}
</style>