import { About, Home, Contact, NotFound } from '@/views';
import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router';

const DESC_SUFFIX = 'Elevate beauty with EstheticStudio - Skincare, lash artistry, brow design, and body treatments. Experience holistic well-being and timeless elegance.';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      transition: 'fade',
      title: 'Home',
      description: `${DESC_SUFFIX}`
    },
  },
  {
    path: '/services',
    name: 'Services',
    component: About,
    meta: {
      transition: 'fade',
      title: 'Services',
      description: `Indulge in rejuvenating beauty services at Esthetic Studio Co. We offer a range of personalized treatments to enhance your natural radiance. Experience the ultimate in self-care and relaxation with our expertise. Book your appointment today!`
    },
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: {
      transition: 'fade',
      title: 'Contact',
      description: `Connect with us at The Esthetic Studio through our contact page. Reach out for inquiries, appointments, or any assistance you need. Your journey to beauty and well being starts here.`
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound
  }
];

function scrollBehavior() {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({ left: 0, top: 0 });
    }, 250);
  });
}

// Create new instance of vue-router
const router = createRouter({
  routes,
  scrollBehavior,
  linkExactActiveClass: 'is-active',
  history: createWebHistory(process.env.BASE_URL)
});

export default router;