<template>
  <footer class="footer">
    <div class="buttons">
      <a
        target="_blank"
        aria-label="Instagram"
        class="button is-medium"
        rel="noopener noreferrer"
        href="https://www.instagram.com/theestheticstudio.co/"
      >
        <font-awesome-icon :icon="['fab', 'instagram']" />
      </a>
      <!-- <a
        target="_blank"
        aria-label="Twitter"
        class="button is-medium"
        rel="noopener noreferrer"
        href="https://github.com/based-ghost"
      >
        <font-awesome-icon :icon="['fab', 'twitter']" />
      </a>
      <a
        target="_blank"
        aria-label="Medium"
        class="button is-medium"
        rel="noopener noreferrer"
        href="https://github.com/based-ghost"
      >
        <font-awesome-icon :icon="['fab', 'etsy']" />
      </a> -->
    </div>
    <div class="content has-text-centered">
      Copyright © {{new Date().getFullYear()}} TheEstheticStudioCo
    </div>
  </footer>
</template>