<template>
  <div class="contact-section">
    <div class="contact-info">
      <div class="contact-info__general"> 
        <h2>The Esthetic Studio Co</h2>
        <p>Miramar, FL 33027</p>
        <div>
          <p>Email Us</p>
        </div>
        <div>
          <a href="tel:+17542028769">
            <p>754.202.8769</p>
          </a>
        </div>
      </div>
      <div class="contact-info__hours">
        <h2>Studio Hours</h2>
        <ul>
          <li><strong>Monday - Friday</strong> 9:00 AM - 7:00 PM</li>
          <li><strong>Saturday</strong> 10:00 AM - 4:00 PM</li>
          <li><strong>Sunday</strong> Closed</li>
        </ul>
      </div>
    </div>

    <div class="horz-line"></div>
    <div class="form-container">
      <h2>Get In Touch!</h2>
      <p v-if="showErrorMessage" class="error">{{ showErrorMessage }}</p>
      <form 
        v-if="!isEmailSent"
        class="form" @submit.prevent="submit"
      >
        <div class="form__group field">
          <input 
            class="form__field" 
            placeholder="Name" 
            name="name" 
            id='name' 
            type="input" 
            v-model="name"
            required
          />
          <label for="name" class="form__label">Name</label>
        </div>
        <div class="form__group field">
          <input 
            class="form__field" 
            placeholder="Email"
            name="email"
            id="email"
            type="input"
            v-model="email"
            required
          /> 
          <label 
            for="email"
            class="form__label"
          >
            Email
          </label>
        </div>
        <div class="form__group field">
          <textarea 
            v-model="message" 
            class="form__field" 
            placeholder="Message" 
            name="message" 
            id="message" 
          />
          <label 
            for="message" 
            class="form__label"
          >
            Message
          </label>
        </div>
        <button 
          class="form__button"
          type="submit"
        >
          Submit
        </button>
      </form>

      <div class="thank-you-message" v-else>
        <p>Thank you for your message</p>
        <p>We will get back to you shortly!</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted, onUnmounted} from 'vue';
import moment from 'moment';

const name = ref('');
const email = ref('');
const message = ref('');
const isEmailSent = ref(false);
const showErrorMessage = ref("");
let script;

onMounted(() => {
  script = document.createElement("script");
  script.src = "https://smtpjs.com/v3/smtp.js";
  script.async = true;
  script.setAttribute("data-status", "loading");
  document.head.append(script); 
})

onUnmounted(() => {
  document.head.removeChild(script);
})


function submit() {
  showErrorMessage.value = "";
  if (!formValid()) {
    showErrorMessage.value = "Email is invalid!"
    return;
  }
  if (!localStorage.getItem("messages")) {
    localStorage.setItem("messages", JSON.stringify([]));
  }
  const messages = JSON.parse(localStorage.getItem("messages"));
  messages.push({
    name,
    email,
    message,
  });
  localStorage.setItem("messages", JSON.stringify(messages));

  window.Email.send({
    Host : "smtp.elasticemail.com",
    Username : process.env._EMAIL_USERNAME,
    Password : process.env._EMAIL_PASSWORD,
    To : 'theestheticstudio.co@gmail.com',
    From : 'theestheticstudio.co@gmail.com',
    Subject : `Inquiry: ${name.value} ${moment().format('MM-DD-YYYY')}`,
    Body : `
    ${name.value}
    <br />
    ${email.value}
    <br />
    ${message.value}
    `
  }).then((msg) => {
      localStorage.removeItem('messages');
      isEmailSent.value = true;
    }
  );
}

function formValid() {
  return (
    name.value.length > 0 &&
    /(.+)@(.+){2,}\.(.+){2,}/.test(email.value) &&
    message.value.length > 0
  );
}

</script>

<style lang="scss" scoped>
@import '../assets/style/base/variables.scss';

.contact-section {
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  @include renderMobileDevice {
    flex-direction: column;
  }

  @include renderTabletDevice {
    flex-direction: column;
  }

  h2 {
    font-size: 1.3em;
    font-weight: 500;
  }

  .horz-line {
    border-left: 2px solid;
    height: 250px;
    width: 5px;

    @include renderMobileDevice {
      display: none;
    }

    @include renderTabletDevice {
      display: none;
    }
  }
}

.contact-info {
  display: flex;
  flex-direction: column;
  align-items: cenrter;
  justify-content: center;
  width: 100%;
  padding:  0 25px 25px 25px;

  h2 {
    margin-bottom: 20px;
  }

  &__general {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding:  0 25px 25px 25px;
    
    p {
      font-size: .9rem;
    }

    a p {
      color: $color-hero-is-dark;
    }
  }

  &__hours {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 25px;

    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    li {
      font-size: .9rem;
    }
  }
}

.form-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;

  h2 {
    font-size: 1.3rem;
  }
}

.form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 25px;
}
.form__group {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
  width: 100%;
  min-width: 350px;
  max-width: 450px;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 2px solid;
  outline: 0;
  font-size: .9rem;
  color: $color-green-highlight;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;

  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown ~ .form__label {
    font-size: .9rem;
    cursor: text;
    top: 20px;
  }
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: $color-green-highlight;
}

.form__field:focus {
  ~ .form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: .9rem;
    color: $theme-primary-body-bg-color;
    font-weight: 500;    
  }
  padding-bottom: 6px;  
  font-weight: 500;
  border-width: 2px;
  border-image: linear-gradient(to right, $theme-primary-body-bg-color, $theme-secondary-body-bg-color);
  border-image-slice: 1;
}
/* reset input */
.form__field{
  &:required,&:invalid { box-shadow:none; }
}

.form__button {
  height: 50px;
  width: 150px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  background-color: $theme-primary-body-bg-color;
  border: none;
  border-radius: 30px;

  &:hover {
    cursor: pointer;
    color: $color-green-highlight;
  }
}

.thank-you-message {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 300px;
}

.error {
  color: red;
}
</style>